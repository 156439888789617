import request from "@/utils/request";

// 频道列表
export function channelList(params) {
  return request({
    url: "/qd/HomePage/liveChannel",
    method: "get",
    params,
  });
}

// 轮播图列表
export function slideList(params) {
  return request({
    url: "/qd/HomePage/slideshow",
    method: "get",
    params,
  });
}

//   最新活动列表
export function newList(data) {
  return request({
    url: "/qd/HomePage/liveList",
    method: "post",
    data,
  });
}

//   搜索
export function searchList(data) {
  return request({
    url: "/qd/HomePage/searchKeyword",
    method: "post",
    data,
  });
}
