<template>
  <div class="search-pc">
    <pc-header></pc-header>
    <div class="main">
      <div class="search-wrap">
        <div class="icon">
          <img src="../../assets/pcSearch.png" alt="" />
        </div>
        <input
          class="search-input"
          v-model="searchKey"
          type="text"
          @keydown.enter="getSearchList(searchKey)"
        />
        <button class="search-btn" @click="getSearchList(searchKey)">
          搜索
        </button>
      </div>
      <div class="search-result" v-if="isShow">
        搜索关键词“<span style="font-weight: bold">{{ key }}</span
        >”共查询到<span style="font-weight: bold">{{ count }}</span
        >个结果
      </div>
      <div class="live-wrap">
        <pc-video
          v-for="(item, i) in dataList"
          :key="i"
          :data="item"
          @refDataList="getSearchList(key)"
        ></pc-video>
      </div>
    </div>
    <pc-footer></pc-footer>
  </div>
</template>

<script>
import PcHeader from "../../components/pc-header.vue";
import PcFooter from "../../components/pc-footer.vue";
import PcVideo from "../../components/pc-video.vue";
import { searchList } from "../../api/home";
export default {
  components: {
    PcHeader,
    PcFooter,
    PcVideo,
  },
  data() {
    return {
      searchKey: "",
      dataList: [],
      key: "",
      count: "",
      isShow: false,
    };
  },
  methods: {
    getSearchList(key) {
      searchList({
        key: key,
      }).then((res) => {
        if (res.code == 0) {
          this.dataList = res.data;
          this.key = res.key;
          this.count = res.count;
          this.isShow = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search-pc {
  height: 100%;
  background: url("../../assets/pcbg.png") 0 0 no-repeat;
  background-size: 100% auto;
  .main {
    min-height: 500px;
    width: 1200px;
    margin: 0 auto;
    padding: 60px 0;
    .search-wrap {
      width: 100%;
      height: 86px;
      background: #fff;
      border-radius: 8px;
      display: flex;
      align-items: center;
      font-size: 20px;
      .icon {
        position: relative;
        width: 100px;
        height: 100%;
        img {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          width: 40px;
          display: block;
        }
      }
      .search-input {
        font-size: 20px;
        flex-grow: 1;
        height: 100%;
        border: none;
        padding: 0;
        outline: none;
      }
      .search-btn {
        font-size: 20px;
        border: none;
        width: 90px;
        height: 50px;
        border-radius: 25px;
        margin-right: 20px;
        color: #fff;
        background: #49b67b;
        cursor: pointer;
      }
    }
    .search-result {
      font-size: 20px;
      margin: 45px 0 20px;
      color: #fff;
    }
    .live-wrap {
      display: flex;
      flex-wrap: wrap;
      .pc-video {
        margin-bottom: 35px;
      }
      .pc-video:nth-child(3n-1) {
        margin: 0 15px 35px;
      }
    }
  }
}
</style>
