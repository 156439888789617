<template>
  <div class="search-mob">
    <mob-header :interaction="false"></mob-header>
    <div class="main">
      <div class="search-wrap">
        <div class="icon">
          <img src="../../assets/pcSearch.png" alt="" />
        </div>
        <input class="search-input" v-model="searchKey" type="text" />
        <button class="search-btn" @click="getSearchList(searchKey)">
          搜索
        </button>
      </div>
      <div class="search-result" v-if="isShow">
        搜索关键词“<span style="color: #49b67b">{{ key }}</span
        >”共查询到<span style="color: #49b67b; font-weight: bold">{{
          count
        }}</span
        >个结果
      </div>
      <!-- <img src="../../assets/shareLogo.png" alt=""> -->
      <mob-video
        v-for="(item, i) in dataList"
        :key="i"
        :data="item"
        @refDataList="getSearchList(key)"
      ></mob-video>
    </div>
  </div>
</template>

<script>
import MobHeader from "../../components/mob-header.vue";
import MobVideo from "../../components/mob-video.vue";
import { searchList } from "../../api/home";
import wechat from "../../utils/wxShare";
import { wechatConfig } from "../../api/user";
export default {
  components: {
    MobHeader,
    MobVideo,
  },
  data() {
    return {
      searchKey: "",
      dataList: [],
      key: "",
      count: "",
      isShow: false,
    };
  },
  created() {
    this.getWechatConfig();
  },
  methods: {
    getWechatConfig() {
      wechatConfig({
        url: location.href,
      }).then((res) => {
        if (res.code == 0) {
          this.wxConfigData = res.data;
          wechat.share(
            this.wxConfigData,
            "因美纳在线讲堂",
            "因美纳在线讲堂",
            this.$store.state.url,
            location.href
          );
        }
      });
    },
    getSearchList(key) {
      searchList({
        key: key,
      }).then((res) => {
        if (res.code == 0) {
          this.dataList = res.data;
          this.key = res.key;
          this.count = res.count;
          this.isShow = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search-mob {
  height: 100%;
  background: url("../../assets/mobbgsmall.png") 0 0 no-repeat;
  background-size: 100% auto;
  .main {
    padding: 0.35rem 0.1rem;
    .search-wrap {
      width: 100%;
      height: 0.43rem;
      background: #fff;
      border-radius: 8px;
      display: flex;
      align-items: center;
      font-size: 0.14rem;
      .icon {
        position: relative;
        width: 0.5rem;
        height: 100%;
        img {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          width: 0.2rem;
          display: block;
        }
      }
      .search-input {
        // width: 2.35rem;
        flex-grow: 1;
        height: 100%;
        border: none;
        padding: 0;
        outline: none;
        font-size: 0.14rem;
      }
      .search-btn {
        border: none;
        width: 0.6rem;
        height: 0.3rem;
        border-radius: 0.15rem;
        margin-right: 0.1rem;
        color: #fff;
        background: #49b67b;
        font-size: 0.14rem;
      }
    }
    .search-result {
      font-size: 0.12rem;
      margin: 0.2rem 0 0.1rem;
      color: #999;
    }
  }
}
</style>
