<template>
  <div class="page-view">
    <search-mob v-if="isMobile"></search-mob>
    <search-pc v-else></search-pc>
  </div>
</template>

<script>
import SearchPc from "./search-pc.vue";
import SearchMob from "./search-mob.vue";
export default {
  components: {
    SearchPc,
    SearchMob,
  },
  metaInfo: {
    title: "搜索",
  },
  data() {
    return {
      isMobile: this.$isMobile(),
    };
  },
};
</script>
